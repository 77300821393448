import React, { createContext, useState } from 'react';
import axios from 'axios'; // Assuming axios is used for API calls

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [files, setFiles] = useState([]);
  const [results, setResults] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);

  const checkPassword = (password) => {
    return password === 'ArthuretBrioche';
  };

  const processFiles = async (files) => {
    setIsProcessing(true);
    setError(null);
    setDownloadUrl(null);
    try {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append('files', file);
      });
      const response = await axios.post(`${process.env.REACT_APP_API_URL || ''}/process`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setResults(response.data);
      if (response.data.file_path) {
        setDownloadUrl(`${process.env.REACT_APP_API_URL || ''}/download/${encodeURIComponent(response.data.file_path)}`);
      }
    } catch (error) {
      console.error('Error processing files:', error);
      setError('Error processing files. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <AppContext.Provider value={{ files, setFiles, results, setResults, isProcessing, processFiles, error, setError, downloadUrl, checkPassword }}>
      {children}
    </AppContext.Provider>
  );
};