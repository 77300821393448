import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Container } from '../styles/AppleStyledComponents';
import Hero from '../components/Hero';
import FileUpload from '../components/FileUpload';
import ProcessButton from '../components/ProcessButton';
import ResultsDisplay from '../components/ResultsDisplay';
import FileList from '../components/FileList';
import { AppContext } from '../context/AppContext';
import axios from 'axios';

const LandingPageContainer = styled(Container)`
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 40px;
`;

const PDFIcon = styled.div`
  width: 100px;
  height: 100px;
  background-image: url('/pdf-icon.png');
  background-size: cover;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const DownloadButton = styled.button`
  background-color: #34c759;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2aa147;
  }

  &:disabled {
    background-color: #b0b0b0;
    cursor: not-allowed;
  }
`;

const LandingPage = () => {
  const { downloadUrl } = useContext(AppContext);
  const [showPDFModal, setShowPDFModal] = useState(false);

  const handleDownload = async () => {
    if (downloadUrl) {
      try {
        const response = await axios.get(downloadUrl, {
          responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'results.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } catch (error) {
        console.error('Error downloading file:', error);
        // You might want to show an error message to the user here
      }
    }
  };

  const handlePDFIconClick = () => {
    setShowPDFModal(true);
  };

  const handlePDFIconDragStart = (e) => {
    e.dataTransfer.setData('text/plain', 'sample-pdf');
  };

  return (
    <>
      <Hero />
      <LandingPageContainer>
        <FileUpload />
        <FileList />
        <ButtonContainer>
          <ProcessButton />
          {downloadUrl && (
            <DownloadButton onClick={handleDownload}>
              Download CSV
            </DownloadButton>
          )}
        </ButtonContainer>
        <ResultsDisplay />
      </LandingPageContainer>
    </>
  );
};

export default LandingPage;