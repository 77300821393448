import React, { useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from '../context/AppContext';

const ResultsContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ResultsTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const ScrollableContainer = styled.div`
  width: 20%; // Reduced from 90% to 45%
  max-height: 250px; // Reduced from 500px to 250px
  overflow-y: auto;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
`;

const SectionTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const Th = styled.th`
  text-align: left;
  padding: 12px;
  background-color: #f5f5f7;
  border-bottom: 1px solid #e0e0e0;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const Td = styled.td`
  padding: 12px;
  border-bottom: 1px solid #e0e0e0;
`;

const DownloadButton = styled.button`
  background-color: #007aff;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const ResultsDisplay = () => {
  const { results, downloadUrl } = useContext(AppContext);

  if (!results || !results.data) {
    return null;
  }

  const handleDownload = () => {
    if (downloadUrl) {
      window.location.href = downloadUrl;
    }
  };

  const renderTable = (data, sectionName) => {
    if (!data || data.length === 0) return null;

    const headers = Object.keys(data[0]);

    return (
      <div key={sectionName}>
        <SectionTitle>{sectionName}</SectionTitle>
        <Table>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <Th key={index}>{header}</Th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {headers.map((header, cellIndex) => (
                  <Td key={cellIndex}>{row[header] || ''}</Td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };

  return (
    <ResultsContainer>
      <ResultsTitle>Loan Termsheet Analysis Results</ResultsTitle>
      {results.message && <p>{results.message}</p>}
      <ScrollableContainer>
        {Object.entries(results.data).map(([sectionName, sectionData]) => 
          renderTable(sectionData, sectionName)
        )}
      </ScrollableContainer>
      {results.error && <p style={{ color: 'red' }}>{results.error}</p>}
    </ResultsContainer>
  );
};

export default ResultsDisplay;