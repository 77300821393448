import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from '../context/AppContext';
import CircularProgress from './CircularProgress';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButton = styled.button`
  background-color: #007aff;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #b0b0b0;
    cursor: not-allowed;
  }
`;

const PasswordButton = styled(StyledButton)`
  font-size: 14px;
  padding: 6px 16px;
  margin-right: 8px; // Add margin to the right of the password button
`;

const CancelButton = styled(PasswordButton)`
  background-color: #e0e0e0;
  color: #333;
  margin-left: 3px; // Add margin to the left of the cancel button

  &:hover {
    background-color: #c0c0c0;
  }
`;

const PasswordText = styled.p`
  font-size: 14px;
  color: #86868b;
  margin-top: 14px;
  
`;

const PasswordInput = styled.input`
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 8px;
  width: 200px; // Increase the width of the input field
`;

const ProcessButton = () => {
  const { files, processFiles, isProcessing, checkPassword } = useContext(AppContext);
  const [showPasswordPrompt, setShowPasswordPrompt] = useState(false);

  const handleClick = () => {
    if (files.length > 0) {
      setShowPasswordPrompt(true);
    }
  };

  const handlePasswordSubmit = (password) => {
    if (checkPassword(password)) {
      processFiles(files);
      setShowPasswordPrompt(false);
    } else {
      alert("Incorrect password. If you don't have the password, please contact Arthur.orts@gmail.com for more information.");
    }
  };

  return (
    <ButtonContainer>
      <StyledButton onClick={handleClick} disabled={files.length === 0 || isProcessing}>
        {isProcessing ? 'Processing...' : 'Process Files'}
      </StyledButton>
      {isProcessing && <CircularProgress />}
      {showPasswordPrompt && (
        <PasswordPrompt onSubmit={handlePasswordSubmit} onCancel={() => setShowPasswordPrompt(false)} />
      )}
    </ButtonContainer>
  );
};

const PasswordPrompt = ({ onSubmit, onCancel }) => {
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(password);
  };

  return (
    <div style={{ marginTop: '10px', textAlign: 'center' }}>
      <form onSubmit={handleSubmit}>
        <PasswordInput
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter password"
        />
        <PasswordButton type="submit">Submit</PasswordButton>
        <CancelButton type="button" onClick={onCancel}>Cancel</CancelButton>
      </form>
      <PasswordText>
        If you don't have the password, please contact Arthur.orts@gmail.com for more information.
      </PasswordText>
    </div>
  );
};

export default ProcessButton;