import React from 'react';
import styled from 'styled-components';
import { Container } from '../styles/AppleStyledComponents';

const HeroContainer = styled.div`
  background-color: #ffffff;
  padding: 60px 0;
  text-align: center;
`;

const HeroTitle = styled.h1`
  font-size: 48px;
  font-weight: 600;
  color: #1d1d1f;
  margin-bottom: 16px;
`;

const HeroSubtitle = styled.p`
  font-size: 21px;
  color: #86868b;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.5;
`;

const Hero = () => {
  return (
    <HeroContainer>
      <Container>
        <HeroTitle>AssetReader</HeroTitle>
        <HeroSubtitle>
          AI-powered Financial termsheet analysis - Extract and explain key information from your  documents instantly.
        </HeroSubtitle>
      </Container>
    </HeroContainer>
  );
};

export default Hero;